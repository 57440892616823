import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box, List } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import imgWorkCover from "../assets/image/lento/shop.png";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Webshop</Text>
                  <Title className="my-4 text-center">
                    LENTO
                    <br/>
                    Webshop for ethical fashion.
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    Lento is a fashion Webshop for men, together with my business partner we aim to be a one-stop shop
                    for men's fashion that you cannot find in the regular stores.
                    We aim to build a brand that is sustainable and ethical. We want to be a brand that is transparent
                    about where our products are made and how they are made.
                    The clothing is unique and looks and is made for men that want to stand out in a crowd with a
                    conscious mind.
                    <br/><br/>
                    I've built this using NextJS as storefront for Shopify. Using shopify as a headless CMS. The Webshop
                    is soon to be launched. A small side note here is that I've
                    started to build my own Webshop API as a Laravel Backend (because I thought it was fun to do), the
                    downside was that it takes too much time to build and maintain, anyway, back to the story.
                    <br/><br/>
                    NextJS allows a developer to use the power of React, and make pre-build pages. Pre-building pages
                    has three major benefits.
                    <br/><br/>
                    <List>
                      <li>
                        Quicker page loads.
                      </li>
                      <li>
                        Better SEO.
                      </li>
                      <li>
                        API and Frontend in one
                      </li>
                    </List>

                    The two first points have SEO benefits. Page speed improves your ranking in Google
                    and pre-building pages makes sure you don't need Javascript active for a computer to understand what
                    the page is about, of course that Javascript will be loaded when a user is really interacting with
                    the page.
                    <br/><br/>
                    The API and frontend in one is a major benefit, it allows 'secrets' to be stored in the same project.
                    Thus tightly coupling the API and the frontend, which is a major benefit in terms of maintainability.
                    <br/><br/>
                    These factors have a major impact in how the web is evolving and how the future of Web shop
                    building will look like.
                    <br/><br/>
                    To reduce operational costs, I've decided to be almost as independent on external platforms as
                    possible (initially I even had the idea to implement a simple backend to add products and
                    categories). This gave me the opportunity to experiment with new technologies (e.g., self-hosted
                    open source projects), see the list below of what I've worked with to make this work.
                  </Text>
                </Box>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text className="pt-4 pb-2" css={`font-weight: 800`} variant="h4">
                    Experiences gained here:
                  </Text>
                  <List>
                    <li>NextJS</li>
                    <li>Deepening my knowledge of React.</li>
                    <li>This is a self-hosted open source project that is able to run arbitrary code on your server.
                      Allowing automatic builds and deployments.
                    </li>
                    <li>Self-hosted private docker registry.</li>
                    <li>GraphQL (using shopify API)</li>
                    <li>Drone for CI/CD.</li>
                    <li>Docker swarm, Docker Networking.</li>
                    <li>I already have extensive knowledge of Docker except now I wanted to experiment with rolling
                      releases.
                    </li>
                    <li>Because our requirements were that also non-programmers should be able to edit pages, I've
                      chosen to work with Builder.io. This platform allows you to mix your React Components and still be
                      able to 'click and drag' some pages. This is
                      very convenient to keep the tech lower at pages where you get often page updates that can be done
                      by a marketeer instead of a programmer.
                    </li>
                  </List>
                </Box>
              </Col>
            </Row>

            <div className="mt-lg-3">
              <Container>
                <Row>
                  <Col lg="4" className="mb-4 mb-lg-0">
                    <Text variant="tag">Company</Text>
                    <Title variant="cardBig" className="mt-3">
                      LENTO
                    </Title>
                  </Col>
                  <Col lg="4" className="mb-4 mb-lg-0">
                    <Text variant="tag">Time active</Text>
                    <Title variant="cardBig" className="mt-3">
                      January 2020 - Present
                    </Title>
                  </Col>
                  <Col lg="4" className="d-flex justify-content-lg-end">
                    <a href="https://efxd.lento.fashion" target="_blank">
                      <Button arrowRight>Lento</Button>
                    </a>
                  </Col>
                </Row>
              </Container>
            </div>
            <Section className="mt-lg-5 pb-0">
              <Container>
                <Row>
                  <Col xs="12" className="mb-5">
                    {/*<img src='../assets/image/lento/shop.png' alt="" className="img-fluid w-100" />*/}
                  </Col>
                </Row>
              </Container>

              <Box>
                <img src={imgWorkCover} alt="" className="img-fluid w-100" />
              </Box>

            </Section>

          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
